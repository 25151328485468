import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Criar uma instância do aplicativo
const app = createApp(App);

// Uso do Vue Router
app.use(router);

// Montar a aplicação no elemento com o ID "app"
app.mount('#app');
