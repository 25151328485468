<template>
  <div class="bg-blue-100 min-h-screen flex items-center justify-center">
    <div class="bg-white p-6 rounded-lg shadow-lg">
      <div
        @drop.prevent="onFileDrop"
        @dragover.prevent
        @dragenter.prevent
        @click="triggerFileInput"
        class="drop-area border-dashed border-4 border-blue-300 p-6 rounded w-full mb-4 flex justify-center items-center cursor-pointer"
      >
        <span>Arraste e solte os arquivos aqui ou clique para selecionar</span>
        <input
          type="file"
          multiple
          @change="onFileChange"
          ref="fileInput"
          class="hidden"
        />
      </div>
      <div v-if="selectedFiles.length">
        <h3>Arquivos para Upload</h3>
        <ul>
          <li v-for="(file, index) in selectedFiles" :key="index">
            {{ file.name }}
            <button @click="removeFile(index)" class="bg-red-500 text-white p-2 rounded hover:bg-red-600 flex justify-center items-center">Remover</button>
          </li>
        </ul>
      </div>
      <button
        @click="uploadFile"
        :disabled="isLoading"
        class="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 flex justify-center items-center"
      >
        <span v-if="isLoading" class="mr-2">
          <img
            src="https://api.iconify.design/line-md/uploading-loop.svg?color=white"
            alt="Carregando"
          />
        </span>
        {{ isLoading ? "Carregando..." : "Upload" }}
      </button>

      <div v-if="errorMsg" class="text-red-500">{{ errorMsg }}</div>

      <div
        v-for="uploadedFile in uploadedFiles"
        :key="uploadedFile.id"
        class="mt-4"
      >
        <a
          :href="uploadedFile.url"
          target="_blank"
          class="text-blue-500 hover:underline"
        >
          {{ uploadedFile.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { db, storage } from "../firebase.js";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { query, orderBy, collection, addDoc, getDocs } from "firebase/firestore";
const uploadedFiles = ref([]);
const isLoading = ref(false);
const errorMsg = ref("");
const fileInput = ref(null);
const selectedFiles = ref([]);

const triggerFileInput = () => {
  fileInput.value.click();
};
const removeFile = (index) => {
  selectedFiles.value.splice(index, 1);
};
const onFileChange = (e) => {
  selectedFiles.value.push(...Array.from(e.target.files));
  errorMsg.value = "";
};
const onFileDrop = (e) => {
  selectedFiles.value.push(...Array.from(e.dataTransfer.files));
  errorMsg.value = "";
  fileInput.value.value = ''; // Resetar o input file
};

const uploadFile = async () => {
  if (!selectedFiles.value.length) {
    errorMsg.value = "Por favor, selecione arquivos para fazer upload.";
    return;
  }

  isLoading.value = true;
  errorMsg.value = "";

  for (const file of selectedFiles.value) {
    const newStorageRef = storageRef(storage, file.name);

    try {
      const snapshot = await uploadBytes(newStorageRef, file);
      const url = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, "files"), {
        name: file.name,
        url: url,
        timestamp: new Date()
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      errorMsg.value = "Erro ao fazer upload: " + error.message;
      break; // Interrompe o loop se ocorrer um erro
    }
  }

  fetchFiles();
  isLoading.value = false;
  fileInput.value.value = ""; // Resetar o input file após o upload
  selectedFiles.value = [];
};

const fetchFiles = async () => {
  isLoading.value = true;
  try {
    const filesRef = collection(db, "files");
    const q = query(filesRef, orderBy("timestamp", "desc")); // Ordena por timestamp decrescente

    const querySnapshot = await getDocs(q);
    uploadedFiles.value = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching files:", error);
    errorMsg.value = "Erro ao buscar arquivos: " + error.message;
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchFiles);
</script>

<style>
.drop-area {
  /* Estilos para a área de arrastar e soltar */
}
</style>
