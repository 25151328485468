import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCoW3pu-SKTO_qWyqEiGokopDhC5jXVc8E",
  authDomain: "prime-arte-adobe.firebaseapp.com",
  databaseURL: "https://prime-arte-adobe-default-rtdb.firebaseio.com",
  projectId: "prime-arte-adobe",
  storageBucket: "prime-arte-adobe.appspot.com",
  messagingSenderId: "620424612529",
  appId: "1:620424612529:web:4e2f93849b4c2b5d10b090",
  measurementId: "G-H0TYR50NBW",
};

const app = initializeApp(firebaseConfig);

// Obtenha uma instância do Firestore e do Storage
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
